<template>
<div>
    
    <!-- banner start -->
    <section
      class="banner_sec global_program"
    >
      <div class="banner_hed">
        <h1 class="heading_2">Are You Global Ready?</h1>
      </div>
    </section>

    <!-- heading start -->
    <section class="bg_grey heading_sec">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <div class="heading_box webinars_heading_banner">
              <h3 class="heading_3">Are you managing a global workforce? 
                </h3>
              <h3 class="heading_3">We’ve got your back.</h3>
            </div>
          </div>
           <div class="col-md-12">
            <div class="learn_more_btn text-center upMar">
              <button  v-scroll-to="{el: '#why-choose1',easing: 'linear',offset: -250,}" class="no-border">
                <img src="../../assets/home/images/down_arrow.png" alt="img" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- heading end -->

    <!-- banner end -->

    <!-- right left text start -->
    <section class="right_left_text_sec padding_100" id="why-choose1">
      <div class="container">
        <div class="row">
          <div class="col-md-5 col-xl-5">
            <div class="right_text_virtual">
              <h5 class="heading_5">Ergo Everywhere</h5>
              <p>
                No matter where your employees are working these days, making sure that they  working safely, productively and setup ergonomically, is essential.  We can help your employees anywhere in the world through virtual evaluations. 

              </p>
            </div>
          </div>
          <div class="col-md-2 col-xl-1">
            <div class="web_arrow fix-ipad">
              <img src="../../assets/home/images/webinars/arrow.svg" alt="img" />
            </div>
           </div>
          <div class="col-md-5 col-xl-6">
            <div class="right_text_virtual virtual_right_box">
              <h5 class="heading_5">Global Program Considerations</h5>
              <ul class="m-0 pl_22">

                <li><p>Health and safety compliance</p></li>
                <li>
                  <p>Cultural needs</p>
                </li>
                <li>
                  <p>Data security</p>
                </li>
                <li>
                  <p>Languages</p>
                </li>
                <li>
                  <p>Equipment procurement</p>
                </li>
                <li>
                  <p>Regional support</p>
                </li>
              </ul>
            </div>
          </div>

          <div class="col-md-12">
            <div class="webinars_btn text-center mt_60">
              <router-link :to="{ name : 'learn_more' }" class="btn btn_primary">Let’s Talk</router-link>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- right left text end -->

    <!-- let ergo start -->
    <section class="heres_sec let_ergo bg_grey padding_100">
      <div class="container">
        <div class="row justify-content-center new_bottom">
          <div class="col-md-12">
            <h3 class="heading_3 font_bold text_black mb_92 text-center">
              Let Ergo-Ally Help!
            </h3>
          </div>
          <div class="col-md-12 col-lg-4 col-xl-3">
            <div class="heres_box">
              <img src="../../assets/home/images/self_assessments/Self.svg" alt="icon">
              <h6 class="heading_6">Self-Assessment & Training</h6>
            </div>
          </div>

          <div class="col-md-6 col-lg-4 col-xl-3">
            <div class="heres_box">
              <img src="../../assets/home/images/self_assessments/Backend.svg" alt="icon">
              <h6 class="heading_6">Backend Data & Metrics</h6>
            </div>
          </div>

          <div class="col-md-6 col-lg-4 col-xl-3">
            <div class="heres_box">
              <img src="../../assets/home/images/global_program/global.svg" alt="icon">
              <h6 class="heading_6">Global <br> Reach</h6>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- let ergo end -->
   
    <!-- request start -->

    <section id="counter-stats" class="cnter wow fadeInRight py_70 bg_primary" data-wow-duration="1.4s">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <div class="request_btn text-center">
              <router-link :to="{ name : 'learn_more' }" class="m-auto"><h2>Request</h2>
                <p>A Demo Today!</p></router-link>
              
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- request end -->

</div>
 
</template>

<script>
// import { mapGetters } from "vuex";
import store from "@/store/index";

export default {
  name: "global_program",
  mounted() {
    store.dispatch("Home/getHomeData").then(() => {});
  },
};
</script>