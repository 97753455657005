var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_vm._m(0),_c('section',{staticClass:"bg_grey heading_sec"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_vm._m(1),_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"learn_more_btn text-center upMar"},[_c('button',{directives:[{name:"scroll-to",rawName:"v-scroll-to",value:({el: '#why-choose1',easing: 'linear',offset: -250,}),expression:"{el: '#why-choose1',easing: 'linear',offset: -250,}"}],staticClass:"no-border"},[_c('img',{attrs:{"src":require("../../assets/home/images/down_arrow.png"),"alt":"img"}})])])])])])]),_c('section',{staticClass:"right_left_text_sec padding_100",attrs:{"id":"why-choose1"}},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_vm._m(2),_vm._m(3),_vm._m(4),_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"webinars_btn text-center mt_60"},[_c('router-link',{staticClass:"btn btn_primary",attrs:{"to":{ name : 'learn_more' }}},[_vm._v("Let’s Talk")])],1)])])])]),_vm._m(5),_c('section',{staticClass:"cnter wow fadeInRight py_70 bg_primary",attrs:{"id":"counter-stats","data-wow-duration":"1.4s"}},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"request_btn text-center"},[_c('router-link',{staticClass:"m-auto",attrs:{"to":{ name : 'learn_more' }}},[_c('h2',[_vm._v("Request")]),_c('p',[_vm._v("A Demo Today!")])])],1)])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"banner_sec global_program"},[_c('div',{staticClass:"banner_hed"},[_c('h1',{staticClass:"heading_2"},[_vm._v("Are You Global Ready?")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"heading_box webinars_heading_banner"},[_c('h3',{staticClass:"heading_3"},[_vm._v("Are you managing a global workforce? ")]),_c('h3',{staticClass:"heading_3"},[_vm._v("We’ve got your back.")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-md-5 col-xl-5"},[_c('div',{staticClass:"right_text_virtual"},[_c('h5',{staticClass:"heading_5"},[_vm._v("Ergo Everywhere")]),_c('p',[_vm._v(" No matter where your employees are working these days, making sure that they working safely, productively and setup ergonomically, is essential. We can help your employees anywhere in the world through virtual evaluations. ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-md-2 col-xl-1"},[_c('div',{staticClass:"web_arrow fix-ipad"},[_c('img',{attrs:{"src":require("../../assets/home/images/webinars/arrow.svg"),"alt":"img"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-md-5 col-xl-6"},[_c('div',{staticClass:"right_text_virtual virtual_right_box"},[_c('h5',{staticClass:"heading_5"},[_vm._v("Global Program Considerations")]),_c('ul',{staticClass:"m-0 pl_22"},[_c('li',[_c('p',[_vm._v("Health and safety compliance")])]),_c('li',[_c('p',[_vm._v("Cultural needs")])]),_c('li',[_c('p',[_vm._v("Data security")])]),_c('li',[_c('p',[_vm._v("Languages")])]),_c('li',[_c('p',[_vm._v("Equipment procurement")])]),_c('li',[_c('p',[_vm._v("Regional support")])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"heres_sec let_ergo bg_grey padding_100"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row justify-content-center new_bottom"},[_c('div',{staticClass:"col-md-12"},[_c('h3',{staticClass:"heading_3 font_bold text_black mb_92 text-center"},[_vm._v(" Let Ergo-Ally Help! ")])]),_c('div',{staticClass:"col-md-12 col-lg-4 col-xl-3"},[_c('div',{staticClass:"heres_box"},[_c('img',{attrs:{"src":require("../../assets/home/images/self_assessments/Self.svg"),"alt":"icon"}}),_c('h6',{staticClass:"heading_6"},[_vm._v("Self-Assessment & Training")])])]),_c('div',{staticClass:"col-md-6 col-lg-4 col-xl-3"},[_c('div',{staticClass:"heres_box"},[_c('img',{attrs:{"src":require("../../assets/home/images/self_assessments/Backend.svg"),"alt":"icon"}}),_c('h6',{staticClass:"heading_6"},[_vm._v("Backend Data & Metrics")])])]),_c('div',{staticClass:"col-md-6 col-lg-4 col-xl-3"},[_c('div',{staticClass:"heres_box"},[_c('img',{attrs:{"src":require("../../assets/home/images/global_program/global.svg"),"alt":"icon"}}),_c('h6',{staticClass:"heading_6"},[_vm._v("Global "),_c('br'),_vm._v(" Reach")])])])])])])
}]

export { render, staticRenderFns }